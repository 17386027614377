import React from "react";
import { Segment, Container, Grid, Header, Button, List, Divider, Image } from "semantic-ui-react";
import { Link } from 'gatsby';


import Layout from "../../../components/Layout";
import FeaturesTable from "./features";

export const info = {
  title : 'Authentication',
  icon : 'key',
  subtitle: 'Remove the friction from authentication'
}

export { FeaturesTable };

export default () => (
  <Layout section="products" title={info.title}>
    <div style={{ position: 'relative' }}>
      <div style={{ background: 'rgb(30,45,179)', paddingBottom: '100px' }}
      >
        <Container text style={{ padding: "4em 0" }}>
          <Header
            as="h1"
            content={info.title}
            inverted
            style={{
              fontSize: "3em",
              fontWeight: "normal",
              marginBottom: 0,
              marginTop: "1.5em",
            }}
          />
          <Header
            as="h2"
            content={info.subtitle}
            inverted
            style={{
              fontSize: "1.7em",
              fontWeight: "normal",
              marginTop: "1.5em",
            }}
          />
          <a
            href="https://appexchange.salesforce.com/appxListingDetail?listingId=a0N3A00000GE6liUAD"
            target="_blank"
          >
            <Button inverted>Try now</Button>
          </a>
          <Header.Subheader style={{ color: "lightgray", margin: "8px" }}>
            Free 30 Day Trial
          </Header.Subheader>
        </Container>
      </div>
      <div className="wave-divider">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
        </svg>
      </div>
    </div>

    <Segment style={{ padding: "2em" }} vertical>
      <Container>
        <Grid columns={2} stackable>

          <Grid.Row style={{margin: '2em 0'}}>
            <Grid.Column verticalAlign="middle">
              <Header as="h1">Make your login a delight to your users</Header>
              <p>Built on top of Experience Cloud, you can use the power of Experience Builder, Visualforce, ... to make your login experience delightfull</p>
              <List bulleted>
                <List.Item>Brand every pixel on the login pages</List.Item>
                <List.Item>Use custom domains</List.Item>
                <List.Item>Integrate Social Identities & Enterprise login</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <Image src="/images/authentication.jpg" alt="Custom Authentication Experience"
                bordered
                style={{
                  boxShadow: "0 1px 2px 0 rgba(34,36,38,.15)",
                  padding: "16px",
                }}/>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{margin: '2em 0'}}>
            <Grid.Column>
              <Image src="/images/authenticator-chooser-draw.jpg" alt="Offer different authenticators"
                bordered
                style={{
                  boxShadow: "0 1px 2px 0 rgba(34,36,38,.15)",
                  padding: "16px",
                }}/>
            </Grid.Column>
            <Grid.Column verticalAlign="middle">
              <Header as="h1">Use the right authentication method for each user</Header>
              <p>Each one of us has preferences when it comes to authentication, why not allow users to choose what to use</p>
              <List bulleted>
                <List.Item>Username & Password</List.Item>
                <List.Item>Biometrics</List.Item>
                <List.Item>Social Login</List.Item>
                <List.Item>Enterprise login</List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{margin: '2em 0'}}>
            <Grid.Column verticalAlign="middle">
              <Header as="h1">Go passwordless today</Header>
              <p>Passwords have always been difficult for users, get rid of them <b>today</b>.</p>
              <p>Enable passwordless authentication: </p>
              <List bulleted>
                <List.Item>Biometrics</List.Item>
                <List.Item>Security Keys</List.Item>
                <List.Item>Push notifications</List.Item>
                <List.Item>SMS</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <Image src="/images/passwordless-authentication.jpg" alt="Custom Authentication Experience"
                bordered
                style={{
                  boxShadow: "0 1px 2px 0 rgba(34,36,38,.15)",
                  padding: "16px",
                }}/>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{margin: '2em 0'}}>
            <Grid.Column>
              <Image src="/images/build-your-own-authenticator.jpg" alt="Bring Your Own Authenticator"
                bordered
                style={{
                  boxShadow: "0 1px 2px 0 rgba(34,36,38,.15)",
                  padding: "16px",
                }}/>
            </Grid.Column>
            <Grid.Column verticalAlign="middle">
              <Header as="h1">Bring Your Own Authenticator</Header>
              <p>CYM Identity adds extra authenticators like biometrics on top of Salesforce Authenticators (SMS, TOTP, Push, ...).</p>
              <p>If it's not enough, you can build your own authenticators (SMS with Twilio Verify, Push notifications with Amazon SNS, ...).</p>
              <p>Your developers can use our <Link to="/docs/guides/authenticators">detailed documentation</Link> to get started.</p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{margin: '2em 0'}}>
            <Grid.Column verticalAlign="middle">
              <Header as="h1">Authenticated users can access apps built outside of Salesforce</Header>
              <p>Give your users Single Sign On across your complete ecosystem</p>
              <p>You can use the same login experience to secure applications built outside Salesforce</p>
              <List bulleted>
                <List.Item>Single Page Applications</List.Item>
                <List.Item>Web Applications</List.Item>
                <List.Item>Mobile apps</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <Image src="/images/application-sdks.jpg" alt="Let users access applications outside of Salesforce"
                bordered
                style={{
                  boxShadow: "0 1px 2px 0 rgba(34,36,38,.15)",
                  padding: "16px",
                }}/>
            </Grid.Column>
          </Grid.Row>

        </Grid>

        <Divider horizontal style={{ margin: "6em 0 4em" }}>
          <span>CYM Identity Features</span>
        </Divider>

        <FeaturesTable />
      </Container>
    </Segment>

  </Layout>
);