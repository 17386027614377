import React from "react";

import { Icon, Table, Popup, Label } from "semantic-ui-react";

const FeaturesTable = () => (
  <Table celled textAlign="center" unstackable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell width={8}></Table.HeaderCell>
        <Table.HeaderCell width={4}>Salesforce Identity</Table.HeaderCell>
        <Table.HeaderCell width={4}>CYM Identity</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      <Table.Row>
        <Table.Cell textAlign="left">Custom domains</Table.Cell>
        <Table.Cell colSpan="2">
          <Icon name="check circle outline" size="big" color="green" />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell textAlign="left">Per Community</Table.Cell>
        <Table.Cell>
          <Icon name="check circle outline" size="big" color="green" />
        </Table.Cell>
        <Table.Cell>
          <Icon name="check circle outline" size="big" color="green" />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell textAlign="left">Per Realm</Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell>
          <Icon name="check circle outline" size="big" color="green" />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell textAlign="left">Per Application</Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell>
          <Icon name="check circle outline" size="big" color="green" />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell textAlign="left">Consent Pages</Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell>
          <Icon name="check circle outline" size="big" color="green" />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell textAlign="left">Multifactor Authentication</Table.Cell>
        <Table.Cell>
          <Popup trigger={<Label>Limited</Label>}>
            You can't brand Push Notifications & U2F
          </Popup>
        </Table.Cell>
        <Table.Cell>
          <Icon name="check circle outline" size="big" color="green" />
        </Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
);

export default FeaturesTable;
