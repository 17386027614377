import React from "react";
import { Segment, Container, Grid, Header, Button, List, Divider, Image } from "semantic-ui-react";
import { Link } from "gatsby";
import Layout from "../../../components/Layout";
import FeaturesTable from "./features";


export const info = {
  title : 'Applications',
  icon : 'desktop',
  subtitle: 'Secure every application you own'
}

export { FeaturesTable };

export default () => (
  <Layout section="products" title={info.title}>
    <div style={{ position: 'relative' }}>
      <div style={{ background: 'rgb(30,45,179)', paddingBottom: '100px' }}>
		    <Container text style={{ padding: "4em 0" }}>
          <Header
            as="h1"
            content={info.title}
            inverted
            style={{
              fontSize: "3em",
              fontWeight: "normal",
              marginBottom: 0,
              marginTop: "1.5em",
            }}
          />
          <Header
            as="h2"
            content={info.subtitle}
            inverted
            style={{
              fontSize: "1.7em",
              fontWeight: "normal",
              marginTop: "1.5em",
            }}
          />
          <Header
            as="h3"
            content="Web, Mobile, Javascript, ..."
            inverted
            style={{
              fontSize: "1.2em",
              fontWeight: "normal",
              marginTop: "1.2em",
            }}
          />
          <a
            href="https://appexchange.salesforce.com/appxListingDetail?listingId=a0N3A00000GE6liUAD"
            target="_blank"
          >
            <Button inverted>Try now</Button>
          </a>
          <Header.Subheader style={{ color: "lightgray", margin: "8px" }}>
            Free 30 Day Trial
          </Header.Subheader>
        </Container>
      </div>
      <div className="wave-divider">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
        </svg>
      </div>
    </div>

    <Segment style={{ padding: "2em" }} vertical>
      <Container>
        <Grid columns={2} stackable>

          <Grid.Row style={{margin: '2em 0'}}>
            <Grid.Column verticalAlign="middle">
              <Header as="h1">
                Control User Information transmitted to each application
              </Header>
              <p>In an era of data privacy concerns, you must ensure that user information is shared appropriately. Keep tight control over the PII which each application has access to</p>
            </Grid.Column>
            <Grid.Column>
              <Image src="/images/application-scopes.jpg" alt="Individual App Policies"
                bordered
                style={{
                  boxShadow: "0 1px 2px 0 rgba(34,36,38,.15)",
                  padding: "16px",
                }}/>
            </Grid.Column>
          </Grid.Row>

          <Divider />

          <Grid.Row style={{margin: '2em 0'}}>
            <Grid.Column>
              <Image src="/images/OpenID-DCR.jpg" alt="Application Repository"
                bordered
                style={{
                  boxShadow: "0 1px 2px 0 rgba(34,36,38,.15)",
                  padding: "16px",
                }}/>
            </Grid.Column>
            <Grid.Column verticalAlign="middle">
              <Header as="h1">
                Keep control over all your applications
              </Header>
              <p>Each API must be protected, configure the right policy to only allow the right access</p>
              <List bulleted>
                <List.Item>An extensible data model which allows you to track the correct information for each application</List.Item>
                <List.Item>Powered by Standard OpenID Connect, create and manage applications on the fly from your Developer Portal</List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>

          <Divider />

          <Grid.Row style={{margin: '2em 0'}}>
            <Grid.Column verticalAlign="middle">
              <Header as="h1">
                Integrate Strong Identity Practices quickly
              </Header>
              <p>Don't waste weeks creating a login, password & multifactor flow, integrate CYM-Identity quickly with our existing SDKs</p>
              <p>We have a selection of recommended OpenSource SDKs :</p>
              <List bulleted>
                <List.Item>Web Applications</List.Item>
                <List.Item>Native Mobile apps : Android or iOS</List.Item>
                <List.Item>Desktop apps and thick clients</List.Item>
              </List>
              <p>
                <Link to="/docs/develop">Start now</Link>
              </p>
            </Grid.Column>
            <Grid.Column>
              <Image src="/images/application-sdks.jpg" alt="Application SDKs"
                bordered
                style={{
                  boxShadow: "0 1px 2px 0 rgba(34,36,38,.15)",
                  padding: "16px",
                }}/>
            </Grid.Column>
          </Grid.Row>
        </Grid>



        <Divider horizontal style={{ margin: "6em 0 4em" }}>
          <span>CYM Identity Features</span>
        </Divider>

        <FeaturesTable />
      </Container>
    </Segment>

  </Layout>
);
