import { Link } from "gatsby";
import React from "react";
import { Segment, Container, Grid, Header, Button, List, Divider, Image } from "semantic-ui-react";
import Layout from "../../../components/Layout";
import FeaturesTable from "./features";

export const info = {
  title : 'Multifactor Authentication',
  icon : 'mobile alternate',
  subtitle: 'Secure your users and digital assets'
}

export { FeaturesTable };

export default () => (
  <Layout section="products" title={info.title}>
    <div style={{ position: 'relative' }}>
      <div style={{ background: 'rgb(30,45,179)', paddingBottom: '100px' }}>
		    <Container text style={{ padding: "4em 0" }}>
           <Header
            as="h1"
            content={info.title}
            inverted
            style={{
              fontSize: "3em",
              fontWeight: "normal",
              marginBottom: 0,
              marginTop: "1.5em",
            }}
          />
            <Header
              as="h2"
              content={info.subtitle}
              inverted
              style={{
                fontSize: "1.7em",
                fontWeight: "normal",
                marginTop: "1.5em",
              }}
            />
            <a
              href="https://appexchange.salesforce.com/appxListingDetail?listingId=a0N3A00000GE6liUAD"
              target="_blank"
            >
              <Button inverted>Try now</Button>
            </a>
          <Header.Subheader style={{ color: "lightgray", margin: "8px" }}>
            Free 30 Day Trial
          </Header.Subheader>
        </Container>

      </div>
      <div className="wave-divider">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
        </svg>
      </div>
    </div>

    <Segment style={{ padding: "2em" }} vertical>
      <Container>
        <Grid columns={2} stackable>

          <Grid.Row style={{margin: '2em 0'}}>
            <Grid.Column verticalAlign="middle">
              <Header as="h1">
                Secure every interaction individually
              </Header>
              <p>Apply security policies for each request and require the right factor at the right time</p>
              <List bulleted>
                <List.Item>Let each application evaluate the user action risk and trigger the right MFA factor</List.Item>
                <List.Item>Let administrators define MFA levels</List.Item>
                <List.Item>Control how often users are prompted to enter their second factor</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <Image src="/images/acr-policies.jpg" alt="MFA policies"
                bordered
                style={{
                  boxShadow: "0 1px 2px 0 rgba(34,36,38,.15)",
                  padding: "16px",
                }}/>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{margin: '2em 0'}}>
            <Grid.Column>
              <Image src="/images/step-up-authentication.jpg" alt="Step up authentication"
                bordered
                style={{
                  boxShadow: "0 1px 2px 0 rgba(34,36,38,.15)",
                  padding: "16px",
                }}/>
            </Grid.Column>
            <Grid.Column verticalAlign="middle">
              <Header as="h1">
                Step up authentication
              </Header>
              <p>Use out-of-band push notifications or browser based flows to complete step up authentication</p>
              <List bulleted>
                <List.Item>Use <Link to="/docs/develop/rest/ciba">OpenID Client-Initiated Backchannel Authentication</Link> for standard out-of-band push notifications</List.Item>
                <List.Item>Use WebAuthn for browser based Step Up flows</List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{margin: '2em 0'}}>
            <Grid.Column verticalAlign="middle">
              <Header as="h1">Bring Your Own Authenticator</Header>
              <p>CYM Identity adds extra authenticators like biometrics on top of Salesforce Authenticators (SMS, TOTP, Push, ...).</p>
              <p>If it's not enough, you can build your own authenticators (SMS with Twilio Verify, Push notifications with Amazon SNS, ...).</p>
              <p>Your developers can use our detailed documentation to get started.</p>
            </Grid.Column>
            <Grid.Column>
              <Image src="/images/build-your-own-authenticator.jpg" alt="Bring Your Own Authenticator"
                bordered
                style={{
                  boxShadow: "0 1px 2px 0 rgba(34,36,38,.15)",
                  padding: "16px",
                }}/>
            </Grid.Column>
          </Grid.Row>

        </Grid>

        <Divider horizontal style={{ margin: "6em 0 4em" }}>
          <span>CYM Identity Features</span>
        </Divider>

        <FeaturesTable />
      </Container>
    </Segment>

  </Layout>
);

