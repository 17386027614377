import React from "react";

import Layout from "../../components/Layout";
import { Card, Container, Icon, Header, Button } from "semantic-ui-react";
import { Link } from "gatsby";

import { info as AuthenticationInfo } from './authentication';
// import { info as PasswordlessInfo } from './passwordless';
import { info as BrandingInfo } from './branding';
import { info as ApiInfo } from './apis';
import { info as ApplicationInfo } from './apps';
import { info as MFAInfo } from './mfa';

const Feature = ({title, icon = null, description, link}) => (
  <Card as={Link} to={link}>
    <Card.Content>
      <Card.Header>
        <p style={{margin: "1em 0"}}>
          {icon ? <Icon name={icon} size="large" /> : <i className="large icons" />}
        </p>
        <p style={{margin: "1em 0"}}>
          <span>{title}</span>
        </p>
      </Card.Header>
      <Card.Description>
        <p style={{margin: "2em 0"}}>
          {description}
        </p>
      </Card.Description>
    </Card.Content>
    <Card.Content extra>
      <span>Learn more</span>
    </Card.Content>
  </Card>
)

const FeaturesLayout = () => (
  <Layout title="Products" section="products">
    <div style={{ position: 'relative' }}>
      <div style={{ background: 'rgb(30,45,179)', paddingBottom: '100px' }}>

        <Container text style={{ padding: "4em 0" }}>
          <Header
            as="h1"
            inverted
            content="A State of the art Identity Management System"
            style={{
              fontSize: "3em",
              fontWeight: "normal",
            }}
          />
          <Header
            as="h2"
            inverted
            content="Secure every aspect of your digital presence"
            style={{
              fontSize: "1.7em",
              fontWeight: "normal",
              marginTop: "1.5em",
            }}
          />
          <a
            href="https://appexchange.salesforce.com/appxListingDetail?listingId=a0N3A00000GE6liUAD"
            target="_blank"
          >
            <Button inverted>Try now</Button>
          </a>
          <Header.Subheader style={{ color: "lightgray", margin: "8px" }}>
            Free 30 Day Trial
          </Header.Subheader>
        </Container>

      </div>
      <div className="wave-divider">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
        </svg>
      </div>
    </div>

    <Container style={{ padding: "2em" }}>
      <Card.Group centered stackable>

        <Feature title={AuthenticationInfo.title} icon={AuthenticationInfo.icon} description={AuthenticationInfo.subtitle} link="authentication" />
        <Feature title={MFAInfo.title} icon={MFAInfo.icon} description={MFAInfo.subtitle}  link="mfa" />
        <Feature title={BrandingInfo.title} icon={BrandingInfo.icon} description={BrandingInfo.subtitle} link="branding" />
        <Feature title={ApplicationInfo.title} icon={ApplicationInfo.icon} description={ApplicationInfo.subtitle} link="apps" />
        <Feature title={ApiInfo.title} icon={ApiInfo.icon} description={ApiInfo.subtitle} link="apis" />

      </Card.Group>
    </Container>

    <Container textAlign="center" style={{ padding: "2em" }}>
      <h3><Link to="details">See all extra features you get with CYM Identity</Link></h3>
    </Container>

  </Layout>
);

export default FeaturesLayout;