import React from "react";

import { Icon, Table, Popup, Label } from "semantic-ui-react";

const FeaturesTable = () => (
  <Table celled textAlign="center" unstackable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell width={8}></Table.HeaderCell>
        <Table.HeaderCell width={4}>Salesforce Identity</Table.HeaderCell>
        <Table.HeaderCell width={4}>CYM Identity</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      <Table.Row>
        <Table.Cell textAlign="left">SMS</Table.Cell>
        <Table.Cell colSpan="2">
          <Popup
            trigger={
              <Icon name="check circle outline" size="big" color="green" />
            }
          >
            You can reuse Salesforce Identity SMS with CYM Identity
          </Popup>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell textAlign="left">OTP</Table.Cell>
        <Table.Cell colSpan="2">
          <Popup
            trigger={
              <Icon name="check circle outline" size="big" color="green" />
            }
          >
            You can reuse Salesforce Identity OTP with CYM Identity
          </Popup>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell textAlign="left">Push Notifications</Table.Cell>
        <Table.Cell colSpan="2">
          <Popup
            trigger={
              <Icon name="check circle outline" size="big" color="green" />
            }
          >
            You can reuse Salesforce Identity Push Notifications with CYM Identity
          </Popup>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell textAlign="left">Biometrics</Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell>
          <Popup
            trigger={
              <Icon name="check circle outline" size="big" color="green" />
            }
          >
            Authenticate users with TouchId, FaceId, Fingerprints, Windows Hello,
            ...
          </Popup>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell textAlign="left">Security Keys</Table.Cell>
        <Table.Cell>
          <Icon name="check circle outline" size="big" color="green" />
        </Table.Cell>
        <Table.Cell>
          <Popup
            trigger={
              <Icon name="check circle outline" size="big" color="green" />
            }
          >
            While you can reuse Salesforce's U2F feature, you can also use
            Security Keys with WebAuthn
          </Popup>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell textAlign="left">Custom Authenticators</Table.Cell>
        <Table.Cell>
          <Icon name="check circle outline" size="big" color="green" />
        </Table.Cell>
        <Table.Cell>
          <Icon name="check circle outline" size="big" color="green" />
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell textAlign="left">Branding</Table.Cell>
        <Table.Cell>
          <Popup trigger={<Label>Limited</Label>}>
            You can't brand Push Notifications & U2F
          </Popup>
        </Table.Cell>
        <Table.Cell>
          <Icon name="check circle outline" size="big" color="green" />
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell textAlign="left">Step up authentication</Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell>
          <Popup
            trigger={
              <Icon name="check circle outline" size="big" color="green" />
            }
          >
            Use <Label>per request</Label> policies or Client Initiated
            Backchannel Authentication flow
          </Popup>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell textAlign="left">Factor Lifetime Management</Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell>
          <Popup
            trigger={
              <Icon name="check circle outline" size="big" color="green" />
            }
          >
            Choose the frequency by which users should be challenged again
          </Popup>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell textAlign="left">Contextual MFA</Table.Cell>
        <Table.Cell>
          <Popup trigger={<Label>Limited</Label>}>
            You can only trigger MFA on login
          </Popup>
        </Table.Cell>
        <Table.Cell>
          <Popup
            trigger={
              <Icon name="check circle outline" size="big" color="green" />
            }
          >
            Use <Label>per request</Label> or <Label>per application</Label>{" "}
            policies
          </Popup>
        </Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
);


export default FeaturesTable;
